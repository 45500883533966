export const HOME_VIEW_URL = "/api/auto/homeview";

export const ACTIVITIES_URL = "/api/auto/activity";

export const BOOKS_URL = "/api/auto/book";

export const VIDEOS_URL = "/api/auto/video";

export const ARTICLES_URL = "/api/auto/article";

export const PHOTOS_VIEW_URL = "/api/auto/photosview";

export const PHOTO_BANNER_URL = "/api/auto/bannerurl";

export const CV_VIEW_URL = "/api/auto/cvview";

export const SHORT_CV_URL = "/api/files/short-cv";

export const SHORT_CV_POST_URL = "/api/file/short-cv";

export const COMPLETE_CV_POST_URL = "/api/file/complete-cv";

export const COMPLETE_CV_URL = "/api/files/complete-cv";

export const IMAGES_URL = "/api/files/image";

export const PODCASTS_URL = "/api/auto/podcast";
