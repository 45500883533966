import React, { useContext } from 'react';
import ComponentMap from '../../../components/ComponentMap';
import TitleLanguageSwitch from '../../../components/FormattedText/TitleLanguageSwitch';
import Podcast from './Podcast';
import { PODCASTS_URL } from '../../../api/urls';
import AppState from '../../../store/AppState';
import useApiData from '../../../store/hooks/useApiData';
import { SET_PODCASTS } from '../../../store/actionsTypes';

const Podcasts = () => {
  const { state } = useContext(AppState);
  const loading = useApiData(PODCASTS_URL, SET_PODCASTS);

  if (!loading) {
    return (
      <div style={styles.wrapper}>
        <TitleLanguageSwitch
          content={{ title: { data: 'Podcasts' }, englishtitle: { data: 'Podcasts' } }}
          type="h1"
          color="#ffffff"
        />
        <ComponentMap
          Component={Podcast}
          content={state.podcasts}
        />
      </div>
    );
  };
  return null;
};

export default Podcasts;

const styles = {
  wrapper: {
    padding: "var(--content-padding)",
    backgroundColor: '#111111',
  },
};
