import React, { useContext, useState } from 'react';
import View from '../../components/View';
import AdminComponentMap from '../../components/ComponentMap/AdminComponentMap';
import ActivityAdminForm from './ActivityAdminForm';
import Activity from './Activity';
import { SET_ACTIVITIES } from '../../store/actionsTypes';
import AppState from '../../store/AppState';
import { ACTIVITIES_URL } from '../../api/urls';
import useApiData from '../../store/hooks/useApiData';
import Button from '../../components/Button';
import FormDialog from '../../components/FormDialog';
import sortActivities from './utils/sortActivities';

const AdminActivitiesView = () => {
  const loading = useApiData(ACTIVITIES_URL, SET_ACTIVITIES);
  const { state: { activities } } = useContext(AppState);
  const { dispatch } = useContext(AppState);
  const [edit, setEdit] = useState(false);

  const updateActivities = data => {
    dispatch({
      type: SET_ACTIVITIES,
      payload: data,
    })
  };

  const setActivityForm = () => {
    setEdit(true);
  };


  return (
    <View loading={loading} admin>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'center', marginTop: '30px' }}>
        <Button
          title="+ Lägg till ny aktivitet"
          onClick={setActivityForm}
        />
      </div>
      <div style={styles.wrapper}>
        <AdminComponentMap
          content={sortActivities(activities)}
          Component={Activity}
          Form={ActivityAdminForm}
          formName="activityForm"
          updateContent={updateActivities}
        />
      </div>
      <FormDialog open={edit}>
        <ActivityAdminForm
          method="POST"
          closeForm={() => { setEdit(false) }}
          index={0}
          updateContent={updateActivities}
        />
      </FormDialog>
    </View>
  )
};


export default AdminActivitiesView;

const styles = {
  wrapper: {
    padding: 'var(--content-padding)',
  },
};