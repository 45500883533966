import React, { useState, useContext } from "react";
import FileList from "./FileList";
import Button from "../Button";
import FileUpload from "../FileUpload";
import AppState from "../../store/AppState";
import useApiData from "../../store/hooks/useApiData";
import { Dialog, Card } from "@material-ui/core";
import Input from "../FormElements/Input";

const FileSelect = (props) => {
  const {
    title,
    fileType,
    value,
    onChange,
    actionType,
    files,
    buttonVariant,
  } = props;

  const { dispatch } = useContext(AppState);
  const loading = useApiData(`/api/files/${fileType}`, actionType);

  const [displayFileSelect, setDisplayFileSelect] = useState(false);
  const [tab, setTab] = useState("file");

  const updateFileList = ({ data }) => {
    dispatch({
      type: actionType,
      payload: data,
    });
  };

  const renderTabs = () => {
    if (tab === "file") {
      return (
        <div style={styles.innerWrapper}>
          <FileUpload
            endpoint={`/api/file/${fileType}`}
            onChange={updateFileList}
          />
          <FileList
            value={value}
            onChange={onChange}
            files={files}
            actionType={actionType}
            fileType={fileType}
          />
        </div>
      );
    }

    if (tab === "url") {
      return (
        <div style={styles.innerWrapper}>
          <Input style={styles.urlInput} value={value} onChange={onChange} />
          <div style={{ margin: "10px 0" }}>
            <Button
              title="Spara url"
              onClick={() => {
                setDisplayFileSelect(false);
              }}
            />
          </div>
        </div>
      );
    }
  };

  if (loading) {
    return <div>Laddar filer...</div>;
  }

  if (displayFileSelect) {
    return (
      <Dialog
        onClose={() => {
          setDisplayFileSelect(false);
        }}
        open={displayFileSelect}
      >
        <Card style={styles.card}>
          <div style={styles.wrapper}>
            <div style={styles.header}>
              <div
                onClick={() => {
                  setTab("file");
                }}
                style={
                  tab === "file"
                    ? { ...styles.tab, ...styles.selectedTab }
                    : styles.tab
                }
              >
                Mina filer
              </div>
              <div
                onClick={() => {
                  setTab("url");
                }}
                style={
                  tab === "url"
                    ? { ...styles.tab, ...styles.selectedTab }
                    : styles.tab
                }
              >
                Använd URL
              </div>
            </div>

            {renderTabs()}
          </div>
        </Card>
      </Dialog>
    );
  }

  if (fileType === "image" && buttonVariant !== "button") {
    return (
      <div
        style={{
          ...imageStyles.imageWrapper,
          backgroundColor: value ? "transparent" : "#33333388",
        }}
      >
        <div
          style={imageStyles.overLay}
          onClick={() => {
            setDisplayFileSelect(true);
          }}
        >
          <div style={imageStyles.overLayMessage}>
            Klicka för att ändra bild
          </div>
        </div>
        <img
          src={value}
          alt=""
          onClick={() => {
            setDisplayFileSelect(true);
          }}
          style={imageStyles.image}
        />
      </div>
    );
  }

  return (
    <Button
      title={title}
      onClick={() => {
        setDisplayFileSelect(true);
      }}
    />
  );
};

export default FileSelect;

const styles = {
  card: {
    padding: "2rem",
    minWidth: "600px",
  },
  header: {
    borderBottom: "2px solid rgba(0,0,0,0.1)",
    display: "flex",
  },
  innerWrapper: {
    display: "flex",
    flexDirection: "column",
    margin: "10px 0",
    width: "100%",
  },
  tab: {
    padding: "10px 10px",
    fontFamily: "var(--theme-font)",
    textTransform: "uppercase",
    fontSize: "0.8rem",
  },
  selectedTab: {
    color: "var(--purple)",
    fontWeight: 700,
  },
};

const imageStyles = {
  imageWrapper: {
    position: "relative",
    width: "35%",
    borderRadius: "4px",
    minHeight: "20vh",
  },
  overLay: {
    position: "absolute",
    padding: "20px",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
  },
  overLayMessage: {
    border: "3px dotted #dddddd99",
    color: "#dddddd99",
    fontSize: "1.5rem",
    width: "100%",
    height: "100%",
    fontFamily: "var(--theme-font)",
    fontWeight: "700",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
  },
  image: {
    width: "100%",
    borderRadius: "4px",
  },
  urlInput: {
    margin: "10px",
  },
};
