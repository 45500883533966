import {
  SET_HOME_VIEW,
  SET_LANGUAGE,
  UPDATE_FORM,
  SET_IMAGES,
  SET_ACTIVITIES,
  SET_ACTIVITY_FORM,
  SET_BOOKS,
  SET_VIDEOS,
  SET_ARTICLES,
  SET_FORM,
  SET_PHOTOS_VIEW,
  SET_PHOTO_BANNER,
  SET_CV_VIEW,
  SET_SHORT_CV_FILES,
  SET_COMPLETE_CV_FILES,
  SET_PUBLICATION_FILES,
  SET_PODCASTS,
} from "./actionsTypes";
import automaticActionSwitch from "@codewell/automatic-action-switch";

const actionSwitch = (state, action) => {
  switch (action.type) {
    case SET_HOME_VIEW: {
      const [content] = action.payload;
      return {
        ...state,
        homeView: content,
        homeViewForm: content,
      };
    }

    case SET_PHOTOS_VIEW: {
      const [content] = action.payload;
      return {
        ...state,
        photosView: content,
        photosViewForm: content,
      };
    }

    case SET_PHOTO_BANNER: {
      return {
        ...state,
        photoBanner: action.payload,
      };
    }

    case SET_LANGUAGE: {
      return {
        ...state,
        language: action.payload,
      };
    }

    case UPDATE_FORM: {
      return {
        ...state,
        [action.payload.form]: {
          ...state[action.payload.form],
          [action.payload.field]: {
            ...state[action.payload.field],
            data: action.payload.value,
          },
        },
      };
    }

    case SET_IMAGES: {
      return {
        ...state,
        images: action.payload,
      };
    }

    case SET_ACTIVITIES: {
      return {
        ...state,
        activities: action.payload,
      };
    }

    case SET_ACTIVITY_FORM: {
      return {
        ...state,
        activityForm: action.payload,
      };
    }

    case SET_FORM: {
      return {
        ...state,
        [action.payload.formName]: action.payload.formData,
      };
    }

    case SET_BOOKS: {
      return {
        ...state,
        books: action.payload,
      };
    }

    case SET_VIDEOS: {
      return {
        ...state,
        videos: action.payload,
      };
    }

    case SET_ARTICLES: {
      return {
        ...state,
        articles: action.payload,
      };
    }

    case SET_CV_VIEW: {
      const [content] = action.payload;
      return {
        ...state,
        cvview: content,
        cvviewForm: content,
      };
    }

    case SET_SHORT_CV_FILES: {
      return {
        ...state,
        shortCvFiles: action.payload,
      };
    }

    case SET_COMPLETE_CV_FILES: {
      return {
        ...state,
        completeCvFiles: action.payload,
      };
    }

    case SET_PUBLICATION_FILES: {
      return {
        ...state,
        publications: action.payload,
      };
    }

    case SET_PODCASTS: {
      return {
        ...state,
        podcasts: action.payload,
      };
    }

    default: {
      return automaticActionSwitch()(state, action);
    }
  }
};

export default actionSwitch;
