import React, { useContext } from 'react';
import ComponentMap from '../../components/ComponentMap';
import Video from './Video';
import TitleLanguageSwitch from '../../components/FormattedText/TitleLanguageSwitch';
import { VIDEOS_URL } from '../../api/urls';
import { SET_VIDEOS } from '../../store/actionsTypes';
import AppState from '../../store/AppState';
import useApiData from '../../store/hooks/useApiData';

const Videos = () => {
  const { state } = useContext(AppState);
  const loading = useApiData(VIDEOS_URL, SET_VIDEOS);

  if (!loading) {
    return (
      <div style={styles.wrapper}>
        <TitleLanguageSwitch
          content={{ title: { data: 'Video' }, englishtitle: { data: 'Video' } }} type="h1"
          color="#ffffff"
        />
        <ComponentMap
          Component={Video}
          content={state.videos}
        />
      </div>
    );
  }
  return null;
};

export default Videos;

const styles = {
  wrapper: {
    padding: "var(--content-padding)",
    backgroundColor: '#111111',
  },
};
