const postApiData = (endpoint, body) => {
  return fetch(endpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((json) => json.data)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export default postApiData;
