import React, { useContext, useState } from 'react';
import Button from '../../components/Button';
import View from '../../components/View';
import BasicContent from '../../components/BasicContent';
import CVAdminForm from './CVAdminForm';
import { CV_VIEW_URL } from '../../api/urls';
import { SET_CV_VIEW } from '../../store/actionsTypes';
import AppState from '../../store/AppState';
import useApiData from '../../store/hooks/useApiData';
import CvFiles from './CvFiles';

const AdminCVView = () => {
  const [edit, setEdit] = useState(false);
  const { state: { cvview }, dispatch } = useContext(AppState);
  const loading = useApiData(CV_VIEW_URL, SET_CV_VIEW);

  const updateCVView = (data) => {
    dispatch({
      type: SET_CV_VIEW,
      payload: data,
    })
  };

  if (edit) {
    return (
      <View loading={loading} admin>
        <div style={styles.wrapper}>
          <CVAdminForm
            contentUrl={CV_VIEW_URL}
            closeForm={() => { setEdit(false); }}
            updateContent={updateCVView}
          />
        </div>
      </View>
    );
  };
  return (
    <View loading={loading} admin>
      <div style={styles.wrapper}>
        <BasicContent content={cvview} />

        <CvFiles />

        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          <Button
            onClick={() => { setEdit(true); }}
            title="Redigera"
            language=""
            style={{ marginLeft: 'auto' }}
          />
        </div>
      </div>
    </View>
  );
};

export default AdminCVView;

const styles = {
  wrapper: {
    padding: 'var(--content-padding)',
  },
};