import React, { useState } from "react";
import View from "../../components/View";

const SignInView = (props) => {
  const [password, setPassword] = useState("");

  const updatePassword = (event) => {
    const { value } = event.target;
    setPassword(value);
  };

  const signIn = (event) => {
    event.preventDefault();
    const { history } = props;
    const requestData = {
      username: "admin",
      password,
    };
    fetch("/api/signin", {
      method: "post",
      mode: "cors",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          history.push("/admin/");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <View>
      <form onSubmit={signIn} style={styles.wrapper}>
        <h2>Logga in</h2>
        <input
          onChange={updatePassword}
          value={password}
          placeholder="Lösenord"
          type="password"
          style={styles.input}
        />
        <br />
        <button type="submit" style={styles.button}>
          Logga in
        </button>
      </form>
    </View>
  );
};

export default SignInView;

const styles = {
  wrapper: {
    width: "100%",
    padding: "var(--content-padding)",
    flex: "1",
  },
  input: {
    width: "300px",
  },
  button: {
    border: "none",
    backgroundColor: "var(--purple)",
    color: "#ffffff",
    fontFamily: "var(--theme-font)",
    fontWeight: 500,
    fontSize: "1rem",
    padding: "5px 10px",
    borderRadius: "var(--border-radius)",
    borderBottom: "3px solid rgba(0,0,0,0.1)",
    marginTop: "10px",
    cursor: "pointer",
  },
};
