/**
 * 
 * @param {*} activities 
 */
const sortActivities = (activities) => {
  return activities.sort((a, b) => {
    if (a.date.data > b.date.data) {
      return -1;
    }
    if (a.date.data < b.date.data) {
      return 1;
    }
    return 0;
  });
};

export default sortActivities;
