import React from 'react';

const SocialMediaLinks = () => (
  <div style={styles.wrapper}>
    <a
      href="https://twitter.com/PehrW"
      style={styles.socialMediaIcon}
      className="twitter-icon"
    >
      <i className="fab fa-twitter" />
    </a>
    <a
      href="https://www.linkedin.com/in/pehr-wiss%C3%A9n-08176715/"
      style={styles.socialMediaIcon}
      className="linkedin-icon"
    >
      <i className="fab fa-linkedin-in" />
    </a>
  </div>
)

export default SocialMediaLinks;

const styles = {
  wrapper: {
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
  socialMediaIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1.3rem',
    color: '#33333366',
    borderRadius: '50%',
    width: '1.5rem',
    height: '1.5rem',
    padding: '20px',
  }
};
