import React, { useContext } from 'react';
import Form from '../../../components/FormElements/Form';
import Input from '../../../components/FormElements/Input';
import Label from '../../../components/FormElements/Label';
import updateForm from '../../../utils/updateForm';
import AppState from '../../../store/AppState';
import { PODCASTS_URL } from '../../../api/urls';

const PodcastAdminForm = (props) => {
  const {
    closeForm,
    updateContent,
    method,
  } = props;

  const { state: { podcastForm }, dispatch } = useContext(AppState);

  return (
    <div>
      {method === 'POST'
        ? (<h2 style={{ color: "#ffffff" }}>Lägg till podcast</h2>)
        : (<h2 style={{ color: "#ffffff" }}>Redigera podcast</h2>)
      }
      <Form
        endpoint={PODCASTS_URL}
        closeForm={closeForm}
        body={podcastForm}
        updateContent={updateContent}
        method={method}
      >
        <Label title="Soundcloud embed-länk" />
        <Input
          value={podcastForm.podcasturl.data}
          onChange={updateForm(dispatch, 'podcastForm', 'podcasturl')}
        />
      </Form>
    </div>
  )
};

export default PodcastAdminForm;

