import React, { useState, useContext } from "react";
import ImageCounter from "./ImageCounter";
import useApiData from "../../../store/hooks/useApiData";
import AppState from "../../../store/AppState";
import { PHOTO_BANNER_URL } from "../../../api/urls";
import { SET_PHOTO_BANNER } from "../../../store/actionsTypes";

const PhotoBanner = () => {
  const [imageIndex, setImageIndex] = useState(0);

  const {
    state: { photoBanner },
  } = useContext(AppState);
  const { length } = photoBanner;

  const loading = useApiData(PHOTO_BANNER_URL, SET_PHOTO_BANNER);

  const next = () => {
    const newIndex = (imageIndex + 1) % length;
    setImageIndex(newIndex);
  };
  const previous = () => {
    const newIndex = (imageIndex + length - 1) % length;
    setImageIndex(newIndex);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div style={styles.wrapper}>
      <div style={styles.imageWrapper}>
        <i
          style={styles.arrow}
          className="fas fa-chevron-left"
          onClick={previous}
        />
        {length > 0 ? (
          <img style={styles.image} src={photoBanner[imageIndex].url} alt="" />
        ) : (
          <div>Laddar bilder...</div>
        )}
        <i
          style={styles.arrow}
          className="fas fa-chevron-right"
          onClick={next}
        />
      </div>

      <ImageCounter
        images={photoBanner}
        imageIndex={imageIndex}
        onClick={(index) => {
          setImageIndex(index);
        }}
      />
    </div>
  );
};

export default PhotoBanner;

const styles = {
  wrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "80px 0",
  },
  imageWrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    width: "80%",
  },
  arrow: {
    padding: "0 40px",
    fontSize: "1.5rem",
    color: "#33333366",
  },
};
