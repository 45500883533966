import React from 'react';
import uuid from 'uuid/v1';

const ImageCounter = ({ images, imageIndex, onClick }) => (
  <div style={styles.wrapper}>
    {images.map((image, index) => (
      <i
        key={uuid()}
        style={styles.circle}
        className={index === imageIndex ? "fas fa-circle" : "far fa-circle"}
        onClick={() => { onClick(index); }}
      />
    ))}
  </div>
);


export default ImageCounter;

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    margin: '20px 0',
  },
  circle: {
    color: '#66666666',
    margin: '0 5px',
    fontSize: '.8rem',
  },
};