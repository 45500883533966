import React, { useContext } from "react";
import LanguageHeader from "./LanguageHeader";
import HeaderTitle from "./HeaderTitle";
import HeaderLinks from "./HeaderLinks";
import AppStateContext from "../../../store/AppState";
import styled from "styled-components";

const StyledHeader = styled.header`
  width: 100%;
  box-shadow: 0 0 25px 0px rgba(110, 120, 130, 0.1);
  padding: 1rem 5%;
  box-sizing: border-box;
  position: relative;
  z-index: 100;
  display: flex;
  flex-direction: column;
`;

const TitleLinkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 5%;
  box-sizing: border-box;
  flex-wrap: wrap;
  align-items: center;
`;

const Header = (props) => {
  const { admin } = props;
  const { state, dispatch } = useContext(AppStateContext);
  const setLanguage = (state, dispatch) => (language) => {
    dispatch(state, { language });
  };

  return (
    <StyledHeader>
      <LanguageHeader
        setLanguage={setLanguage(state, dispatch)}
        admin={admin}
      />
      <TitleLinkWrapper>
        <HeaderTitle />
        <HeaderLinks admin={admin} />
      </TitleLinkWrapper>
    </StyledHeader>
  );
};

export default Header;
