import React from 'react';
import Books from './Books';
import Articles from './Articles';
import View from '../../components/View';
import Videos from './Videos';
import Podcasts from './Podcasts';

const PublicationsView = () => (
  <View>
    <Books />
    <Videos />
    <Articles />
    <Podcasts />
  </View>
);

export default PublicationsView;
