import React from "react";

import styled from "styled-components";

const StyledInput = styled.input`
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-weight: 400;
  font-family: var(--text-font);
  padding: 10px;
  font-size: 1rem;
`;

const Input = ({ onChange, value, type }) => {
  const update = ({ target: { value } }) => {
    onChange(value);
  };

  return (
    <StyledInput type={type || "text"} defaultValue={value} onChange={update} />
  );
};

export default Input;
