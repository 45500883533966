import { UPDATE_FORM } from "../store/actionsTypes";

const updateForm = (dispatch, form, field) => (value) => {
  dispatch({
    type: UPDATE_FORM,
    payload: {
      form,
      field,
      value,
    }
  });
};

export default updateForm;