import React, { useContext } from "react";
import selected from "./utils/selected";
import removeFile from "./utils/removeFile";
import AppState from "../../store/AppState";

const FileList = (props) => {
  const { value, onChange, files, actionType, fileType } = props;

  const { dispatch } = useContext(AppState);

  const removeFileEvent = (file) => () => {
    removeFile((data) => {
      dispatch({
        type: actionType,
        payload: data,
      });
    }, file);
  };

  return (
    <div style={fileType === "image" ? imageStyles.wrapper : styles.wrapper}>
      {files.map((file) => (
        <div
          key={file._id}
          style={
            fileType === "image" ? imageStyles.imageWrapper : styles.fileWrapper
          }
          onClick={() => onChange(file.url)}
        >
          {selected(file, value) ? (
            <i
              style={
                fileType === "image" ? imageStyles.selected : styles.selected
              }
              className="fas fa-check-circle"
            />
          ) : null}
          {fileType === "image" ? (
            <img style={imageStyles.image} alt="" src={file.url} />
          ) : null}
          <span style={fileType === "image" ? imageStyles.name : styles.name}>
            {file.originalname}
          </span>
          <i
            style={fileType === "image" ? imageStyles.trash : styles.trash}
            onClick={removeFileEvent(file)}
            className="fas fa-trash"
          />
        </div>
      ))}
    </div>
  );
};

export default FileList;

const styles = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    overflowY: "scroll",
    height: "60vh",
    padding: "20px 0",
  },
  fileWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: "1px solid rgba(0,0,0,0.1)",
    padding: "5px 0",
  },
  selected: {
    color: "var(--green)",
    fontSize: "1rem",
  },
  name: {
    padding: "10px",
    fontFamily: "var(--theme-font)",
    color: "#333333ee",
    fontSize: "0.8rem",
  },
  trash: {
    cursor: "pointer",
    marginLeft: "auto",
  },
};

const imageStyles = {
  wrapper: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    margin: "20px 0",
    overflowY: "scroll",
    height: "60vh",
  },
  imageWrapper: {
    position: "relative",
    width: "33%",
    maxWidth: "250px",
    margin: "5px",
    display: "flex",
    flexDirection: "column",
  },
  image: {
    width: "100%",
  },
  name: {
    padding: "10px",
    fontFamily: "var(--theme-font)",
    color: "#333333ee",
    fontSize: "0.8rem",
    width: "100%",
  },
  trash: {
    position: "absolute",
    top: "10px",
    right: "10px",
    width: "2rem",
    height: "2rem",
    backgroundColor: "#ffffff",
    boxShadow: "rgba(0, 0, 0, 0.3) 3px 3px 3px 0px",
    padding: "5px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  selected: {
    position: "absolute",
    display: "flex",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    color: "var(--green)",
    opacity: "0.8",
    fontSize: "5rem",
  },
};
