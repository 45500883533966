import React from "react";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  image: {
    width: "35%",
    float: "left",
    paddingRight: "2rem",
    paddingBottom: "2rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      paddingRight: 0,
    },
  },
}));

const InlineImage = ({ content: { imageurl } }) => {
  const classes = useStyles();
  if (imageurl.data) {
    return <img src={imageurl.data} className={classes.image} alt="" />;
  }
  return null;
};

export default InlineImage;
