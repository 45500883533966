import actionSwitch from "./actionSwitch";
import actionLogger from '@fippli/action-logger';

const reducer = (state, action) => {
  const nextState = actionSwitch(state, action);

  if (process.env.NODE_ENV === 'development') {
    actionLogger(state, action, nextState);
  }

  return nextState;
};


export default reducer;
