import React, { useContext, useState } from 'react';
import View from '../../components/View';
import AdminComponentMap from '../../components/ComponentMap/AdminComponentMap';
import Book from './Book';
import BookAdminForm from './BookAdminForm';
import Video from './Video';
import VideoAdminForm from './VideoAdminForm';
import Article from './Article';
import ArticleAdminForm from './ArticleAdminForm';
import AppState from '../../store/AppState';
import { SET_BOOKS, SET_VIDEOS, SET_ARTICLES, SET_PODCASTS } from '../../store/actionsTypes';
import useApiData from '../../store/hooks/useApiData';
import { BOOKS_URL, VIDEOS_URL, ARTICLES_URL, PODCASTS_URL } from '../../api/urls';
import FormDialog from '../../components/FormDialog';
import Button from '../../components/Button';
import TitleLanguageSwitch from '../../components/FormattedText/TitleLanguageSwitch';
import Podcast from './Podcasts/Podcast';
import PodcastAdminForm from './Podcasts/PodcastAdminForm';

const AdminPublicationsView = () => {
  const { state, dispatch } = useContext(AppState);
  useApiData(BOOKS_URL, SET_BOOKS);
  useApiData(VIDEOS_URL, SET_VIDEOS);
  useApiData(ARTICLES_URL, SET_ARTICLES);
  useApiData(PODCASTS_URL, SET_PODCASTS);
  const [edit, setEdit] = useState('');

  const update = type => (payload) => {
    dispatch({
      type,
      payload,
    });
  }

  return (
    <View admin>
      <div>

        <div style={styles.bookWrapper}>
          <TitleLanguageSwitch
            type="h1"
            content={{
              title: { data: 'Böcker' },
              englishtitle: { data: 'Books' },
            }}
          />
          <br />
          <Button title="+ Lägg till bok" onClick={() => { setEdit('book') }} />
          <AdminComponentMap
            content={state.books}
            Component={Book}
            Form={BookAdminForm}
            formName="bookForm"
            updateContent={update(SET_BOOKS)}
          />
        </div>
        <div style={styles.videoWrapper}>
          <TitleLanguageSwitch
            content={{ title: { data: 'Video' }, englishtitle: { data: 'Video' } }} type="h1"
            color="#ffffff"
          />
          <br />
          <Button title="+ Lägg till video" onClick={() => { setEdit('video') }} />
          <AdminComponentMap
            content={state.videos}
            Component={Video}
            Form={VideoAdminForm}
            formName="videoForm"
            updateContent={update(SET_VIDEOS)}
          />
        </div>
        <div style={styles.articleWrapper}>
          <TitleLanguageSwitch
            content={{ title: { data: 'Artiklar' }, englishtitle: { data: 'Articles' } }}
            type="h1"
          />
          <br />
          <Button title="+ Lägg till artikel" onClick={() => { setEdit('article') }} />
          <AdminComponentMap
            content={state.articles}
            Component={Article}
            Form={ArticleAdminForm}
            formName="articleForm"
            updateContent={update(SET_ARTICLES)}
          />
        </div>
        <div style={styles.videoWrapper}>
          <TitleLanguageSwitch
            content={{ title: { data: 'Podcasts' }, englishtitle: { data: 'Podcasts' } }} type="h1"
            color="#ffffff"
          />
          <br />
          <Button title="+ Lägg till podcast" onClick={() => { setEdit('podcast') }} />
          <AdminComponentMap
            content={state.podcasts}
            Component={Podcast}
            Form={PodcastAdminForm}
            formName="podcastForm"
            updateContent={update(SET_PODCASTS)}
          />
        </div>
      </div>
      <FormDialog open={edit === 'book'}>
        <BookAdminForm
          closeForm={() => { setEdit('') }}
          updateContent={update(SET_BOOKS)}
          method="POST"
        />
      </FormDialog>

      <FormDialog open={edit === 'video'}>
        <VideoAdminForm
          closeForm={() => { setEdit('') }}
          updateContent={update(SET_VIDEOS)}
          method="POST"
        />
      </FormDialog>

      <FormDialog open={edit === 'article'}>
        <ArticleAdminForm
          closeForm={() => { setEdit('') }}
          updateContent={update(SET_ARTICLES)}
          method="POST"
        />
      </FormDialog>

      <FormDialog open={edit === 'podcast'}>
        <PodcastAdminForm
          closeForm={() => { setEdit('') }}
          updateContent={update(SET_PODCASTS)}
          method="POST"
        />
      </FormDialog>
    </View>
  );
};

export default AdminPublicationsView;

const styles = {
  bookWrapper: {
    padding: 'var(--content-padding)',
  },
  videoWrapper: {
    padding: 'var(--content-padding)',
    backgroundColor: '#111111',
  },
  articleWrapper: {
    padding: 'var(--content-padding)',
  },
};