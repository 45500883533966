import React, { useContext } from 'react';
import Form from '../../components/FormElements/Form';
import Input from '../../components/FormElements/Input';
import TextArea from '../../components/FormElements/TextArea';
import Label from '../../components/FormElements/Label';
import { VIDEOS_URL } from '../../api/urls';
import updateForm from '../../utils/updateForm';
import AppState from '../../store/AppState';

const VideoAdminForm = (props) => {
  const {
    closeForm,
    updateContent,
    method,
  } = props;

  const { state: { videoForm }, dispatch } = useContext(AppState);

  return (
    <div>
      {method === 'POST'
        ? (<h2 style={{ color: "#ffffff" }}>Lägg till video</h2>)
        : (<h2 style={{ color: "#ffffff" }}>Redigera video</h2>)
      }
      <Form
        endpoint={VIDEOS_URL}
        closeForm={closeForm}
        body={videoForm}
        updateContent={updateContent}
        method={method}
      >
        <Label title="Svensk titel" />
        <Input
          value={videoForm.title.data}
          onChange={updateForm(dispatch, 'videoForm', 'title')}
        />

        <Label title="Svensk text" />
        <TextArea
          value={videoForm.text.data}
          onChange={updateForm(dispatch, 'videoForm', 'text')}
        />

        <Label title="Engelsk titel" />
        <Input
          value={videoForm.englishtitle.data}
          onChange={updateForm(dispatch, 'videoForm', 'englishtitle')}
        />

        <Label title="Engelsk text" />
        <TextArea
          value={videoForm.englishtext.data}
          onChange={updateForm(dispatch, 'videoForm', 'englishtext')}
        />

        <Label title="youtube-länk" />
        <Input
          value={videoForm.videourl.data}
          onChange={updateForm(dispatch, 'videoForm', 'videourl')}
        />
      </Form>
    </div>
  )
};

export default VideoAdminForm;

