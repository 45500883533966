import React, { useContext } from 'react';
import ComponentMap from '../../components/ComponentMap';
import Book from './Book';
import TitleLanguageSwitch from '../../components/FormattedText/TitleLanguageSwitch';
import useApiData from '../../store/hooks/useApiData';
import { SET_BOOKS } from '../../store/actionsTypes';
import AppState from '../../store/AppState';
import { BOOKS_URL } from '../../api/urls';

const Books = () => {
  const { state } = useContext(AppState);
  const loading = useApiData(BOOKS_URL, SET_BOOKS);

  if (!loading) {
    return (
      <div style={styles.wrapper}>
        <TitleLanguageSwitch
          type="h1"
          content={{
            title: { data: 'Böcker' },
            englishtitle: { data: 'Books' },
          }}
        />
        <ComponentMap
          Component={Book}
          content={state.books}
        />
      </div>
    )
  }
  return null;
};

export default Books;

const styles = {
  wrapper: {
    padding: "var(--content-padding)",
  },
};
