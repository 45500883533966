import React from 'react';
import BasicContent from '../../components/BasicContent';

const Book = ({ content }) => (
  <div style={styles.wrapper}>
    <BasicContent content={content} files={[content.fileurl.data]} />
  </div>
);

export default Book;

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    margin: '5% 0',
    width: '100%',
  },
};
