export const SWEDISH = "SWEDISH";
export const ENGLISH = "ENGLISH";

const basicContentFields = {
  title: {},
  text: {},
  englishtitle: {},
  englishtext: {},
};

const homeviewState = {
  homeView: {},
  homeViewForm: {
    ...basicContentFields,
    imageurl: {},
  },
};

const activityState = {
  activities: [],
};

const publicationsState = {
  books: [],
  videos: [],
  articles: [],
  podcasts: [],
  activityForm: {
    ...basicContentFields,
    date: {},
    imageurl: {},
  },
  bookForm: {
    ...basicContentFields,
    fileurl: {},
    imageurl: {},
  },
  videoForm: {
    ...basicContentFields,
    videourl: {},
  },
  articleForm: {
    ...basicContentFields,
    imageurl: {},
    fileurl: {},
  },
  podcastForm: {
    podcasturl: {},
  },
  publications: [], // The publication files
};

const photosviewState = {
  photosView: {},
  photosViewForm: {},
  photoBanner: [],
};

const cvviewState = {
  cvview: {},
  shortCv: {},
  completeCv: {},
  cvviewForm: {
    ...basicContentFields,
    imageurl: {},
  },
};

const initialState = {
  ...homeviewState,
  ...activityState,
  ...publicationsState,
  ...photosviewState,
  ...cvviewState,
  language: SWEDISH,
  pageContent: {},
  images: [],
  shortCvFiles: [],
  completeCvFiles: [],
  settingsDialog: false,
};

export default initialState;
