import React, { useState, useContext } from 'react';
import Button from '../../components/Button';
import View from '../../components/View';
import PhotosAdminForm from './PhotosAdminForm';
import TitleLanguageSwitch from '../../components/FormattedText/TitleLanguageSwitch';
import FormattedText from '../../components/FormattedText';
import PhotoBanner from './PhotoBanner';
import useApiData from '../../store/hooks/useApiData';
import { PHOTOS_VIEW_URL } from '../../api/urls';
import { SET_PHOTOS_VIEW } from '../../store/actionsTypes';
import AppState from '../../store/AppState';
import AdminPhotoBanner from './PhotoBanner/AdminPhotoBanner';
import FormDialog from '../../components/FormDialog';

const AdminPhotosView = () => {
  const [edit, setEdit] = useState(false);

  const { state: { photosView }, dispatch } = useContext(AppState);

  const loading = useApiData(PHOTOS_VIEW_URL, SET_PHOTOS_VIEW);

  const updateView = data => {
    dispatch({
      type: SET_PHOTOS_VIEW,
      payload: data,
    })
  };

  if (loading) {
    return (
      <div>Loading...</div>
    )
  }

  return (
    <View admin>
      <div style={styles.wrapper}>
        <div style={styles.wrapper}>
          <div style={styles.text}>
            <TitleLanguageSwitch content={photosView} />
            <FormattedText content={photosView} />
          </div>
          <PhotoBanner />
        </div>

        <div style={styles.buttonWrapper}>
          <Button
            onClick={() => { setEdit(true); }}
            title="Redigera"
            language=""
            style={{ marginLeft: 'auto' }}
          />
        </div>
      </div>
      <FormDialog open={edit}>
        <div style={styles.wrapper}>
          <PhotosAdminForm
            contentUrl={PHOTOS_VIEW_URL}
            closeForm={() => { setEdit(false); }}
            updateContent={updateView}
          />
          <AdminPhotoBanner />
        </div>
      </FormDialog>
    </View>
  );
};

export default AdminPhotosView;

const styles = {
  wrapper: {
    padding: 'var(--content-padding)',
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
};