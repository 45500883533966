import React, { useContext } from 'react';
import AppState from '../../store/AppState';
import { ENGLISH } from '../../store/initialState';

const TitleLanguageSwitch = ({ content, type, color }) => {
  const { state } = useContext(AppState);

  if (type === 'h1') {
    return (
      <h1 style={{ color }}>
        {state.language === ENGLISH ? content.englishtitle.data : content.title.data}
      </h1>
    );
  }

  return (
    <span style={{ ...styles.title, color }}>
      {state.language === ENGLISH ? content.englishtitle.data : content.title.data}
      <br />
      <br />
    </span>
  );

};

export default TitleLanguageSwitch;

const styles = {
  title: {
    fontSize: '1.4rem',
    fontWeight: 700,
    fontFamily: 'var(--theme-font)',
    textTransform: 'uppercase',
    margin: 0,
    width: '100%',
  },
};