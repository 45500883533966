import { useEffect, useContext, useState } from 'react';
import fetchApiData from '../../api/fetchApiData';
import AppState from '../AppState';

const useApiData = (url, actionType) => {
  const [loading, setLoading] = useState(true);
  const { dispatch } = useContext(AppState);

  useEffect(() => {
    // Fetch the content from the relevant api
    // set the content in the state on the key
    // that is passed as a param
    if (url) {
      fetchApiData(url)
        .then(data => {
          dispatch({
            type: actionType,
            payload: data,
          });
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [url, actionType, dispatch]);

  return loading;
};

export default useApiData;