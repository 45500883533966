import React, { useContext } from 'react';
import AppState from '../../../store/AppState';
import postApiData from '../../../api/postApiData';
import { PHOTO_BANNER_URL } from '../../../api/urls';
import { SET_PHOTO_BANNER } from '../../../store/actionsTypes';
import deleteApiData from '../../../api/deleteApiData';
import ImageSelect from '../../../components/ImageSelect';

const AdminPhotoBanner = () => {
  // Get all images
  const { state: { photoBanner }, dispatch } = useContext(AppState);

  const chosen = url => photoBanner
    .map(i => i.url)
    .includes(url);

  const addBannerImage = (url) => {
    if (chosen(url)) {
      deleteApiData(PHOTO_BANNER_URL, { url })
        .then(data => {
          dispatch({
            type: SET_PHOTO_BANNER,
            payload: data,
          });

        });
    } else {
      postApiData(PHOTO_BANNER_URL, { url })
        .then(data => {
          dispatch({
            type: SET_PHOTO_BANNER,
            payload: data,
          });
        });
    }
  };

  return (
    <div style={styles.wrapper}>
      <ImageSelect
        title="Välj bilder"
        url={photoBanner}
        onChange={addBannerImage}
        buttonVariant="button"
      />
    </div>
  )
};

export default AdminPhotoBanner;

const styles = {
  wrapper: {
    width: '100%',
  },
  image: {
    width: '200px',
  }
}
