import React, { useContext } from 'react';
import Form from '../../components/FormElements/Form';
import Input from '../../components/FormElements/Input';
import TextArea from '../../components/FormElements/TextArea';
import Label from '../../components/FormElements/Label';
import AppState from '../../store/AppState';
import updateForm from '../../utils/updateForm';
import ImageSelect from '../../components/ImageSelect';
import FileSelect from '../../components/FileSelect';
import { SET_SHORT_CV_FILES, SET_COMPLETE_CV_FILES } from '../../store/actionsTypes';

const CVAdminForm = (props) => {
  const {
    contentUrl,
    closeForm,
    updateContent,
  } = props;

  const {
    state: { cvviewForm, shortCvFiles, completeCvFiles },
    dispatch
  } = useContext(AppState);

  return (
    <div>
      <ImageSelect
        url={cvviewForm.imageurl.data}
        onChange={updateForm(dispatch, 'cvviewForm', 'imageurl')}
      />
      <Form
        endpoint={contentUrl}
        closeForm={closeForm}
        body={cvviewForm}
        updateContent={updateContent}
      >
        <Label title="Svensk titel" />
        <Input
          value={cvviewForm.title.data}
          onChange={updateForm(dispatch, 'cvviewForm', 'title')}
        />

        <Label title="Svensk text" />
        <TextArea
          value={cvviewForm.text.data}
          onChange={updateForm(dispatch, 'cvviewForm', 'text')}
        />

        <Label title="Engelsk titel" />
        <Input
          value={cvviewForm.englishtitle.data}
          onChange={updateForm(dispatch, 'cvviewForm', 'englishtitle')}
        />

        <Label title="Engelsk text" />
        <TextArea
          value={cvviewForm.englishtext.data}
          onChange={updateForm(dispatch, 'cvviewForm', 'englishtext')}
        />


      </Form>

      <FileSelect
        title="Välj kort CV"
        fileType="short-cv"
        files={shortCvFiles}
        value={cvviewForm.shortcv.data}
        onChange={updateForm(dispatch, 'cvviewForm', 'shortcv')}
        actionType={SET_SHORT_CV_FILES}
      />

      <FileSelect
        title="Välj komplett CV"
        fileType="complete-cv"
        files={completeCvFiles}
        value={cvviewForm.completecv.data}
        onChange={updateForm(dispatch, 'cvviewForm', 'completecv')}
        actionType={SET_COMPLETE_CV_FILES}
      />
    </div>
  )
};

export default CVAdminForm;
