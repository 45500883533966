import React from "react";
import FormattedText from "../FormattedText";
import TitleLanguageSwitch from "../FormattedText/TitleLanguageSwitch";
import InlineImage from "./InlineImage";
import FileButton from "../FileButton";

const BasicContent = ({ content, files }) => (
  <div style={styles.wrapper}>
    <p>
      <InlineImage content={content} />
      <TitleLanguageSwitch content={content} />
      <FormattedText content={content} />
      {files ? (
        <div style={{ marginTop: "20px" }}>
          {files.map((file) => (
            <FileButton key={file} url={file} />
          ))}
        </div>
      ) : null}
    </p>
  </div>
);

export default BasicContent;

const styles = {};
