/**
 * Set views
 */
export const SET_HOME_VIEW = "SET_HOME_VIEW";

export const SET_PHOTOS_VIEW = "SET_PHOTOS_VIEW";

/**
 * Utils
 */
export const SET_LANGUAGE = "SET_LANGUAGE";

export const UPDATE_FORM = "UPDATE_FORM";

export const SET_SETTINGS_DIALOG = "SET_SETTINGS_DIALOG";

/**
 * Data
 */

export const SET_IMAGES = "SET_IMAGES";

export const SET_ACTIVITIES = "SET_ACTIVITIES";

export const SET_ACTIVITY_FORM = "SET_ACTIVITY_FORM";

export const SET_FORM = "SET_FORM";

export const SET_PUBLICATION_FILES = "SET_PUBLICATION_FILES";

export const SET_BOOKS = "SET_BOOKS";

export const SET_VIDEOS = "SET_VIDEOS";

export const SET_ARTICLES = "SET_ARTICLES";

export const SET_PODCASTS = "SET_PODCASTS";

export const SET_PHOTO_BANNER = "SET_PHOTO_BANNER";

export const SET_CV_VIEW = "SET_CV_VIEW";

export const SET_SHORT_CV_FILES = "SET_SHORT_CV_FILES";

export const SET_COMPLETE_CV_FILES = "SET_COMPLETE_CV_FILES";
