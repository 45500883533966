import React, { useState } from "react";
import FormDialog from "../../FormDialog";
import styled from "styled-components";
import Button from "../../Button";
import { useContext } from "react";
import AppState from "../../../store/AppState";
import Input from "../../FormElements/Input";
import Label from "../../FormElements/Label";
import { SET_SETTINGS_DIALOG } from "../../../store/actionsTypes";
import putApiData from "../../../api/putApiData";

const Wrapper = styled.div`
  position: relative;
`;

const FormContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Icon = styled.i``;

const ButtonWrapper = styled.div`
  display: flex;
  flex: unset;
  padding: 20px 0;
`;

const Settings = () => {
  const { state, dispatch } = useContext(AppState);
  const [newPassword, setNewPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");

  const openSettings = () => {
    dispatch({
      type: SET_SETTINGS_DIALOG,
      payload: true,
    });
  };

  const closeSettings = () => {
    setOldPassword("");
    setNewPassword("");
    dispatch({
      type: SET_SETTINGS_DIALOG,
      payload: false,
    });
  };

  const updatePassword = () => {
    putApiData("/api/password", { oldPassword, newPassword }).then((data) => {
      if (data === "Password updated") {
        closeSettings();
      }
    });
  };

  return (
    <Wrapper>
      <Button onClick={openSettings}>
        <Icon className="fas fa-tools" />
        Inställningar
      </Button>
      <FormDialog open={state.settingsDialog}>
        <FormContentWrapper>
          <h3>Ändra lösenord</h3>
          <Label title="Nytt lösenord" />
          <Input onChange={setNewPassword} type="password" />
          <Label title="Nuvarande lösenord" />
          <Input onChange={setOldPassword} type="password" />
          <ButtonWrapper>
            <Button
              title="Avbryt"
              onClick={closeSettings}
              style={{ backgroundColor: "var(--pink)", marginLeft: "auto" }}
            />
            <Button
              title="Spara"
              onClick={updatePassword}
              style={{ backgroundColor: "var(--green)", marginLeft: "10px" }}
            />
          </ButtonWrapper>
        </FormContentWrapper>
      </FormDialog>
    </Wrapper>
  );
};

export default Settings;
