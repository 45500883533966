import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { withRouter } from 'react-router-dom';
import useThrowOut from '../../utils/useThrowOut';

const View = withRouter((props) => {
  const {
    children,
    admin,
    backgroundColor,
    loading,
    history,
  } = props;

  useThrowOut(admin, history);

  if (loading) {
    return <div>Loading...</div>
  }

  return (
    <div style={styles.wrapper}>
      <Header admin={admin} />
      <main style={{ ...styles.main, backgroundColor }}>
        {children}
      </main>
      <Footer />
    </div>
  )
});

export default View;

const styles = {
  wrapper: {
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    width: '100%',
    minHeight: '100vh',
  },
};
