import { useEffect } from "react";

const useThrowOut = (admin, history) => {
  useEffect(() => {
    if (admin) {
      fetch("/api/authenticate", {
        mode: "cors",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }).then((response) => {
        const { status } = response;
        if (status === 401) {
          history.push("/signin");
        }
      });
    }
  }, [admin, history]);
};

export default useThrowOut;
