import React, { useContext } from 'react';
import Form from '../../components/FormElements/Form';
import Input from '../../components/FormElements/Input';
import TextArea from '../../components/FormElements/TextArea';
import Label from '../../components/FormElements/Label';
import AppState from '../../store/AppState';
import updateForm from '../../utils/updateForm';
import { ARTICLES_URL } from '../../api/urls';
import ImageSelect from '../../components/ImageSelect';
import FileSelect from '../../components/FileSelect';
import { SET_PUBLICATION_FILES } from '../../store/actionsTypes';

const ArticleAdminForm = (props) => {
  const {
    closeForm,
    updateContent,
    method,
  } = props;

  const { state: { articleForm, publications }, dispatch } = useContext(AppState);

  return (
    <div style={styles.wrapper}>
      {method === 'POST'
        ? (<h2>Lägg till artikel</h2>)
        : (<h2>Redigera artikel</h2>)
      }
      <Form
        endpoint={ARTICLES_URL}
        closeForm={closeForm}
        body={articleForm}
        updateContent={updateContent}
        method={method}
      >

        <Label title="Svensk titel" />
        <Input
          value={articleForm.title.data}
          onChange={updateForm(dispatch, 'articleForm', 'title')}
        />

        <Label title="Svensk text" />
        <TextArea
          value={articleForm.text.data}
          onChange={updateForm(dispatch, 'articleForm', 'text')}
        />

        <Label title="Engelsk titel" />
        <Input
          value={articleForm.englishtitle.data}
          onChange={updateForm(dispatch, 'articleForm', 'englishtitle')}
        />

        <Label title="Engelsk text" />
        <TextArea
          value={articleForm.englishtext.data}
          onChange={updateForm(dispatch, 'articleForm', 'englishtext')}
        />

        <Label title="Bild" />
        <ImageSelect
          url={articleForm.imageurl.data}
          onChange={updateForm(dispatch, 'articleForm', 'imageurl')}
        />

        <Label title="Väl fil" />
        <div>
          <FileSelect
            title="Välj fil"
            fileType="publication"
            files={publications}
            value={articleForm.fileurl.data}
            onChange={updateForm(dispatch, 'articleForm', 'fileurl')}
            actionType={SET_PUBLICATION_FILES}
          />
        </div>
      </Form>
    </div>
  )
};

export default ArticleAdminForm;

const styles = {
  wrapper: {
  },
};
