import React, { useContext } from 'react';
import Form from '../../components/FormElements/Form';
import Input from '../../components/FormElements/Input';
import TextArea from '../../components/FormElements/TextArea';
import Label from '../../components/FormElements/Label';
import ImageSelect from '../../components/ImageSelect';
import AppState from '../../store/AppState';
import updateForm from '../../utils/updateForm';

const HomeAdminForm = (props) => {
  const {
    contentUrl,
    closeForm,
    updateContent,
  } = props;

  const { state: { homeViewForm }, dispatch } = useContext(AppState);

  return (
    <div>
      <ImageSelect
        url={homeViewForm.imageurl.data}
        onChange={updateForm(dispatch, 'homeViewForm', 'imageurl')}
      />
      <Form
        endpoint={contentUrl}
        closeForm={closeForm}
        body={homeViewForm}
        updateContent={updateContent}
      >
        <Label title="Svensk titel" />
        <Input
          value={homeViewForm.title.data}
          onChange={updateForm(dispatch, 'homeViewForm', 'title')}
        />

        <Label title="Svensk text" />
        <TextArea
          value={homeViewForm.text.data}
          onChange={updateForm(dispatch, 'homeViewForm', 'text')}
        />

        <Label title="Engelsk titel" />
        <Input
          value={homeViewForm.englishtitle.data}
          onChange={updateForm(dispatch, 'homeViewForm', 'englishtitle')}
        />

        <Label title="Engelsk text" />
        <TextArea
          value={homeViewForm.englishtext.data}
          onChange={updateForm(dispatch, 'homeViewForm', 'englishtext')}
        />
      </Form>
    </div>
  )
};

export default HomeAdminForm;
