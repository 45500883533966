import React, { useContext } from 'react';
import useApiData from '../../store/hooks/useApiData';
import { SET_IMAGES } from '../../store/actionsTypes';
import FileSelect from '../FileSelect';
import AppState from '../../store/AppState';

const ImageSelect = (props) => {
  const {
    url,
    onChange,
    title,
    buttonVariant,
  } = props;

  const { state: { images } } = useContext(AppState);

  const loading = useApiData(`/api/files/image`, SET_IMAGES);

  if (loading) {
    return (<div>Laddar bilder...</div>);
  }

  return (
    <FileSelect
      fileType="image"
      actionType={SET_IMAGES}
      title={title}
      value={url}
      onChange={onChange}
      files={images}
      buttonVariant={buttonVariant}
    />
  );
};

export default ImageSelect;
