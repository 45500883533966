import React, { useContext } from "react";
import AppState from "../../../store/AppState";
import { SWEDISH, ENGLISH } from "../../../store/initialState";
import { SET_LANGUAGE } from "../../../store/actionsTypes";
import SocialMediaLinks from "./SocialMediaLinks";
import styled from "styled-components";
import Settings from "./Settings";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 3px solid var(--purple);
  box-sizing: border-box;
  padding: 10px;
  align-items: center;
`;

const IconWrapper = styled.div`
  margin-left: auto;
  display: flex;
  flex: unset;
`;

const LanguageFlag = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  margin-left: 10px;
`;

const LanguageHeader = ({ admin }) => {
  const { dispatch } = useContext(AppState);
  const setSwedish = () => {
    dispatch({
      type: SET_LANGUAGE,
      payload: SWEDISH,
    });
  };

  const setEnglish = () => {
    dispatch({
      type: SET_LANGUAGE,
      payload: ENGLISH,
    });
  };

  return (
    <Wrapper>
      <InnerWrapper>
        <SocialMediaLinks />
        <IconWrapper>
          <LanguageFlag
            src="/images/united-kingdom.png"
            alt=""
            onClick={setEnglish}
          />
          <LanguageFlag src="/images/sweden.png" alt="" onClick={setSwedish} />
        </IconWrapper>
        {admin ? <Settings /> : null}
      </InnerWrapper>
    </Wrapper>
  );
};

export default LanguageHeader;
