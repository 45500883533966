import React, { useContext } from 'react';
import Form from '../../components/FormElements/Form';
import Input from '../../components/FormElements/Input';
import TextArea from '../../components/FormElements/TextArea';
import Label from '../../components/FormElements/Label';
import DatePicker from '../../components/FormElements/DatePicker';
import AppState from '../../store/AppState';
import { ACTIVITIES_URL } from '../../api/urls';
import updateForm from '../../utils/updateForm';
import ImageSelect from '../../components/ImageSelect';

const ActivityAdminForm = (props) => {
  const {
    closeForm,
    updateContent,
    method,
  } = props;

  const { state, dispatch } = useContext(AppState);

  return (
    <div>
      {method === 'POST'
        ? (
          <h1>Lägg till aktivitet</h1>
        )
        : <h1>Redigera aktivitet</h1>
      }
      <Form
        endpoint={ACTIVITIES_URL}
        closeForm={closeForm}
        body={state.activityForm}
        updateContent={updateContent}
        method={method}
      >
        <Label title="Datum (yyyy-mm-dd)" />
        <DatePicker
          value={state.activityForm.date.data}
          onChange={updateForm(dispatch, 'activityForm', 'date')}
        />

        <Label title="Svensk titel" />
        <Input
          value={state.activityForm.title.data}
          onChange={updateForm(dispatch, 'activityForm', 'title')}
        />

        <Label title="Svensk text" />
        <TextArea
          value={state.activityForm.text.data}
          onChange={updateForm(dispatch, 'activityForm', 'text')}
        />

        <Label title="Engelsk titel" />
        <Input
          value={state.activityForm.englishtitle.data}
          onChange={updateForm(dispatch, 'activityForm', 'englishtitle')}
        />

        <Label title="Engelsk text" />
        <TextArea
          value={state.activityForm.englishtext.data}
          onChange={updateForm(dispatch, 'activityForm', 'englishtext')}
        />

        <Label title="Bild" />
        <ImageSelect
          url={state.activityForm.imageurl.data}
          onChange={updateForm(dispatch, 'activityForm', 'imageurl')}
        />
      </Form>
    </div>
  )
};

export default ActivityAdminForm;
