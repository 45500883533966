import React, { useContext } from "react";
import ActivityDate from "./ActivityDate";
import AppState from "../../store/AppState";
import FormattedText from "../../components/FormattedText";
import TitleLanguageSwitch from "../../components/FormattedText/TitleLanguageSwitch";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "100%",
    backgroundColor: "#ffffff",
    boxShadow: "0px 0px 25px 1px rgba(110,120,130,0.1)",
    position: "relative",
    marginBottom: "20px",
    marginTop: "60px",
    display: "flex",
    flexDirection: "row",
    borderRadius: "4px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  left: {
    width: "42%",
    paddingRight: "1rem",
    padding: "20px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      width: "100%",
      padding: "10px 0",
    },
  },
  imageWrapper: {
    width: "38%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  image: {
    width: "100%",
    borderTopRightRadius: "4px",
    borderBottomRightRadius: "4px",
  },
}));

const Activity = ({ content }) => {
  const classes = useStyles();
  const { state } = useContext(AppState);

  return (
    <div className={classes.wrapper}>
      <ActivityDate date={content.date.data} language={state.language} />
      <div className={classes.left}>
        <TitleLanguageSwitch content={content} />
        <FormattedText content={content} />
      </div>
      <div className={classes.imageWrapper}>
        <img className={classes.image} src={content.imageurl.data} alt="" />
      </div>
    </div>
  );
};

export default Activity;
