import React from "react";
import styled from "styled-components";

const StyledButton = styled.button`
  border: none;
  background-color: var(--purple);
  color: #ffffff;
  font-family: var(--theme-font);
  font-weight: 500;
  font-size: 13pt;
  padding: 0.5rem 1rem;
  border-radius: var(--border-radius);
  border-bottom: 3px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
`;

const Button = (props) => {
  const { title, englishtitle, language, onClick, style, children } = props;

  return (
    <StyledButton
      type="button"
      style={style}
      className="purple-hover"
      onClick={onClick}
    >
      {children}
      {language === "english" ? englishtitle : title}
    </StyledButton>
  );
};

export default Button;
