import React, { useState, useContext } from 'react';
import uuid from 'uuid/v1';
import Button from '../../components/Button';
import AppState from '../../store/AppState';
import { SET_FORM } from '../../store/actionsTypes';
import FormDialog from '../FormDialog';

const formId = uuid();

const AdminComponentMap = ({ Component, Form, content, formName, updateContent }) => {
  const [edit, setEdit] = useState(Infinity);

  const { dispatch } = useContext(AppState);

  const select = index => () => {
    dispatch({
      type: SET_FORM,
      payload: {
        formName,
        formData: content[index],
      },
    });
    setEdit(index);
  };

  const render = (contentElement, index) => {
    if (edit === index) {
      return (
        <FormDialog open={true}>
          <Form
            key={formId}
            closeForm={() => { setEdit(Infinity) }}
            updateContent={updateContent}
            method="PUT"
            index={index}
          />
        </FormDialog>
      );
    }

    return (
      <div key={uuid()}>
        <Component content={contentElement} />
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          <Button
            onClick={select(index)}
            title="Redigera"
            language=""
            style={{ marginLeft: 'auto' }}
          />
        </div>
      </div>
    );
  };

  return content.map((contentElement, index) => render(contentElement, index));
};

export default AdminComponentMap;
