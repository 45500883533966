const fetchApiData = (endpoint) =>
  fetch(endpoint)
    .then((response) => response.json())
    .then((json) => json.data)
    .then((data) => data)
    .catch((error) => {
      console.error(error);
    });

export default fetchApiData;
