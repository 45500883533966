/**
 * 
 * @param {*} file 
 * @param {*} value 
 */
const selected = (file, value) => {
  if (typeof value === 'string') {
    return value === file.url;
  }
  if (Array.isArray(value)) {
    return value.map(i => i.url).includes(file.url);
  }
};

export default selected;
