import React from 'react';
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  footer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    position: 'sticky',
    backgroundColor: '#efefef',
    boxSizing: 'border-box',
    padding: '2rem',
    fontFamily: 'var(--theme-font)',
    fontWeight: 300,
    fontSize: '0.9rem',
    marginTop: 'auto',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  column: {
    flex: 1,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      marginBottom: '20px',
    },
  },
  icon: {
    fontSize: '2rem',
    padding: '10px',
    color: '#11111188',
  }
}));

const Footer = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <div className={classes.column}>
        <h2>Kontakt</h2>
        <span>mail: <a href="mailto:pehr.wissen@gmail.com?Subject=">pehr.wissen@gmail.com</a></span>
        <br />
        <span>tel: 0706 661 870</span>
      </div>

      <div className={classes.column}>
        <a
          href="https://twitter.com/PehrW"
          className={`twitter-icon ${classes.icon}`}
        >
          <i className="fab fa-twitter" />
        </a>
        <a
          href="https://www.linkedin.com/in/pehr-wiss%C3%A9n-08176715/"
          className={`linkedin-icon ${classes.icon}`}
        >
          <i className="fab fa-linkedin-in" />
        </a>
      </div>

      <div className={classes.column}>
        <span>
          <Link to="/signin">Administratörsinloggning</Link>
        </span>
        <br />
        <span>
          Hemsida skapad av
          &nbsp;
          <a
            href="https://www.linkedin.com/in/filip-johansson-036b9984/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Filip Johansson
          </a>
        </span>
      </div>
    </footer>
  );
};

export default Footer;

