import React from 'react';
import uuid from 'uuid/v1';

const ComponentMap = ({ content, Component }) => (
  <div style={styles.wrapper}>
    {content.map(contentElement => (
      <Component
        key={uuid()}
        content={contentElement}
      />
    ))}
  </div>
);

export default ComponentMap;

const styles = {
  wrapper: {},
};
