/**
 * Input field
 */

import React from "react";

const DatePicker = ({ onChange, value }) => (
  <input
    type="date"
    style={styles}
    value={value}
    onChange={event => { onChange(event.target.value); }}
  />
);

export default DatePicker;

const styles = {
  border: '1px solid rgba(0,0,0,0.1)',
  fontWeight: 400,
  fontFamily: 'var(--text-font)',
  padding: '10px',
};
