import React, { useReducer } from "react";
import AppState from "./store/AppState";
import initialState from "./store/initialState";
import reducer from "./store/reducer";
import Routes from "./Routes";
import { ThemeProvider } from "@material-ui/styles";
import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({});

const App = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <div>
      <AppState.Provider value={{ state, dispatch }}>
        <ThemeProvider theme={theme}>
          <Routes />
        </ThemeProvider>
      </AppState.Provider>
    </div>
  );
};

export default App;
