import React, { useContext } from "react";
import AppState from "../../store/AppState";
import FileButton from "../../components/FileButton";

const CvFiles = () => {
  const {
    state: {
      cvview: { shortcv, completecv },
    },
  } = useContext(AppState);

  return (
    <div style={styles.wrapper}>
      <div style={styles.buttonWrapper}>
        <h3>Kort CV</h3>
        <FileButton url={shortcv.data} />
      </div>

      <div style={styles.buttonWrapper}>
        <h3>Komplett CV</h3>
        <FileButton url={completecv.data} />
      </div>
    </div>
  );
};

export default CvFiles;

const styles = {
  wrapper: {
    display: "flex",
  },
  buttonWrapper: {
    marginRight: "10px",
  },
};
