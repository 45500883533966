import React from "react";
import { Dialog, Card, makeStyles } from "@material-ui/core";
import styled from "styled-components";

const useStyles = makeStyles((theme) => ({
  paperWidthMd: {
    width: "100%",
  },
}));

const StyledCard = styled(Card)`
  width: 100%;
  overflow-y: scroll !important;
  padding: 2rem;
  box-sizing: border-box;
`;

const FormDialog = (props) => {
  const { open, children } = props;

  const classes = useStyles();

  return (
    <Dialog classes={classes} maxWidth="md" open={open} onClose={() => {}}>
      <StyledCard>{children}</StyledCard>
    </Dialog>
  );
};

export default FormDialog;
