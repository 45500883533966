import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import SignInView from './views/SignIn';

import HomeView from './views/Home';
import ActivitiesView from './views/Activities';
import PublicationsView from './views/Publications';
import PhotosView from './views/Photos';
import CVView from './views/CV';

import AdminHomeView from './views/Home/AdminHomeView';
import AdminActivitiesView from './views/Activities/AdminActivitiesView';
import AdminPublicationsView from './views/Publications/AdminPublicationsView.jsx';
import AdminPhotosView from './views/Photos/AdminPhotosView.jsx';
import AdminCVView from './views/CV/AdminCVView.jsx';

const Routes = () => (
  <Router>
    <div>
      {/* Sign in */}
      <Route exact path="/signin" component={SignInView} />

      {/* Normal routes */}
      <Route exact path="/" component={HomeView} />
      <Route exact path="/Activities" component={ActivitiesView} />
      <Route exact path="/Publications" component={PublicationsView} />
      <Route exact path="/Photos" component={PhotosView} />
      <Route exact path="/cv" component={CVView} />

      {/* Admin routes */}
      <Route exact path="/admin" component={AdminHomeView} />
      <Route exact path="/admin/activities" component={AdminActivitiesView} />
      <Route exact path="/admin/publications" component={AdminPublicationsView} />
      <Route exact path="/admin/photos" component={AdminPhotosView} />
      <Route exact path="/admin/cv" component={AdminCVView} />

    </div>
  </Router>
);

export default Routes;
