import React, { useContext } from "react";
import BasicContent from "../../components/BasicContent";
import View from "../../components/View";
import AppState from "../../store/AppState";
import { SET_HOME_VIEW } from "../../store/actionsTypes";
import useApiData from "../../store/hooks/useApiData";
import { HOME_VIEW_URL } from "../../api/urls";

const HomeView = () => {
  const loading = useApiData(HOME_VIEW_URL, SET_HOME_VIEW);
  const {
    state: { homeView },
  } = useContext(AppState);

  return (
    <View loading={loading}>
      <div style={styles.wrapper}>
        <BasicContent content={homeView} />
      </div>
    </View>
  );
};

export default HomeView;

const styles = {
  wrapper: {
    width: "100%",
    padding: "var(--content-padding)",
  },
};
