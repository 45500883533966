import React, { useContext } from 'react';
import Form from '../../components/FormElements/Form';
import Input from '../../components/FormElements/Input';
import TextArea from '../../components/FormElements/TextArea';
import Label from '../../components/FormElements/Label';
import { PHOTOS_VIEW_URL } from '../../api/urls';
import updateForm from '../../utils/updateForm';
import AppState from '../../store/AppState';

const PhotosAdminForm = (props) => {
  const {
    closeForm,
    updateContent,
    method,
  } = props;

  const { state: { photosViewForm }, dispatch } = useContext(AppState);

  return (
    <div>
      <Form
        endpoint={PHOTOS_VIEW_URL}
        closeForm={closeForm}
        body={photosViewForm}
        updateContent={updateContent}
        method={method}
      >
        <Label title="Svensk titel" />
        <Input
          value={photosViewForm.title.data}
          onChange={updateForm(dispatch, 'photosViewForm', 'title')}
        />

        <Label title="Svensk text" />
        <TextArea
          value={photosViewForm.text.data}
          onChange={updateForm(dispatch, 'photosViewForm', 'text')}
        />

        <Label title="Engelsk titel" />
        <Input
          value={photosViewForm.englishtitle.data}
          onChange={updateForm(dispatch, 'photosViewForm', 'englishtitle')}
        />

        <Label title="Engelsk text" />
        <TextArea
          value={photosViewForm.englishtext.data}
          onChange={updateForm(dispatch, 'photosViewForm', 'englishtext')}
        />
      </Form>
    </div>
  )
};

export default PhotosAdminForm;
