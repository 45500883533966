import React, { useContext } from 'react';
import ComponentMap from '../../components/ComponentMap';
import TitleLanguageSwitch from '../../components/FormattedText/TitleLanguageSwitch';
import Article from './Article';
import { ARTICLES_URL } from '../../api/urls';
import AppState from '../../store/AppState';
import useApiData from '../../store/hooks/useApiData';
import { SET_ARTICLES } from '../../store/actionsTypes';

const Articles = () => {
  const { state } = useContext(AppState);
  const loading = useApiData(ARTICLES_URL, SET_ARTICLES);

  if (!loading) {
    return (
      <div style={styles.wrapper}>
        <TitleLanguageSwitch
          content={{ title: { data: 'Artiklar' }, englishtitle: { data: 'Articles' } }}
          type="h1"
        />
        <ComponentMap
          Component={Article}
          content={state.articles}
        />
      </div>
    );
  };
  return null;
};

export default Articles;

const styles = {
  wrapper: {
    padding: "var(--content-padding)",
  },
};
