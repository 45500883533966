import React from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

const StyledHeaderLink = styled.div`
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: rgba(0, 0, 0, 0.1);
  ${(props) =>
      props.selected &&
      css`
        border-bottom-color: var(--green);
      `}
    :hover {
    border-bottom-color: var(--green);
  }
`;

const StyledLink = styled(Link)`
  font-family: var(--theme-font);
  font-size: 0.9rem;
  text-transform: uppercase;
  text-decoration: none;
  padding: 1rem;
  box-sizing: border-box;
  cursor: pointer;
  font-weight: 400;
  display: flex;
`;

const HeaderLink = ({ name, path }) => {
  return (
    <StyledHeaderLink selected={window.location.pathname === path}>
      <StyledLink to={path}>{name}</StyledLink>
    </StyledHeaderLink>
  );
};

export default HeaderLink;
