export const englishMonths = [
  {
    long: "January",
    short: "Jan",
  },
  {
    long: "February",
    short: "Feb",
  },
  {
    long: "March",
    short: "Mar",
  },
  {
    long: "April",
    short: "Apr",
  },
  {
    long: "May",
    short: "May",
  },
  {
    long: "June",
    short: "Jun",
  },
  {
    long: "July",
    short: "Jul",
  },
  {
    long: "August",
    short: "Aug",
  },
  {
    long: "September",
    short: "Sep",
  },
  {
    long: "October",
    short: "Oct",
  },
  {
    long: "November",
    short: "Nov",
  },
  {
    long: "December",
    short: "Dec",
  },
];

export const swedishMonths = [
  {
    long: "Januari",
    short: "Jan",
  },
  {
    long: "Februari",
    short: "Feb",
  },
  {
    long: "Mars",
    short: "Mar",
  },
  {
    long: "April",
    short: "Apr",
  },
  {
    long: "Maj",
    short: "Maj",
  },
  {
    long: "Juni",
    short: "Jun",
  },
  {
    long: "Juli",
    short: "Jul",
  },
  {
    long: "Augusti",
    short: "Aug",
  },
  {
    long: "September",
    short: "Sep",
  },
  {
    long: "Oktober",
    short: "Okt",
  },
  {
    long: "November",
    short: "Nov",
  },
  {
    long: "December",
    short: "Dec",
  },
];
