import React from 'react';

const Podcast = ({ content }) => {
  const getPodcastUrl = ({ podcasturl: { data } }) => {
    const podcastUrl = data.split(' ')
      .filter(e => e.includes('src'))[0]
      .split('"')[1];
    return podcastUrl;
  }
  return (
    <div style={styles.wrapper}>
      <iframe
        width="100%"
        height="300"
        scrolling="no"
        frameborder="no"
        allow="autoplay"
        src={getPodcastUrl(content)}
      />
    </div>
  );
};

export default Podcast;

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    margin: '5% 0',
    width: '100%',
  },
};
