import React from 'react';

const FileUpload = (props) => {
  const {
    endpoint,
    onChange,
  } = props;

  const uploadFile = ({ target }) => {
    const [file] = target.files;
    const body = new FormData();
    body.append('file', file, file.name);
    fetch(endpoint, {
      method: 'post',
      body,
      credentials: 'include',
    })
      .then(response => response.json())
      .then(({ data }) => {
        onChange(data);
      })
      .catch(error => {
        console.log(error);
      })
  };

  return (
    <div style={styles.wrapper}>
      <label htmlFor="fileUpload"
        style={styles.label}>
        <i className="fas fa-file-upload" />
        &nbsp;
        Lägg till fil
        <input
          style={styles.input}
          id="fileUpload"
          type="file"
          onChange={uploadFile}
        />
      </label>
    </div>
  );
};

export default FileUpload;

const styles = {
  wrapper: {
    display: 'flex',
    margin: '10px 0',
  },
  label: {
    border: 'none',
    backgroundColor: 'var(--purple)',
    color: '#ffffff',
    fontFamily: 'var(--theme-font)',
    fontWeight: 500,
    fontSize: '1rem',
    padding: '.5rem 1rem',
    borderRadius: 'var(--border-radius)',
    borderBottom: '3px solid rgba(0,0,0,0.1)',
    cursor: 'pointer',
  },
  input: {
    display: 'none',
  },
};