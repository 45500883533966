import React, { useContext } from 'react';
import BasicContent from '../../components/BasicContent';
import View from '../../components/View';
import AppState from '../../store/AppState';
import useApiData from '../../store/hooks/useApiData';
import { SET_CV_VIEW } from '../../store/actionsTypes';
import { CV_VIEW_URL } from '../../api/urls';
import CvFiles from './CvFiles';

const CVView = () => {
  const { state: { cvview } } = useContext(AppState);
  const loading = useApiData(CV_VIEW_URL, SET_CV_VIEW);

  return (
    <View loading={loading}>
      <div style={styles.wrapper}>
        <BasicContent content={cvview} />
        <CvFiles />
      </div>
    </View>
  );
};

export default CVView;

const styles = {
  wrapper: {
    width: '100%',
    padding: 'var(--content-padding)',
  },
};
