import React from 'react';
import Button from '../Button';
import putApiData from '../../api/putApiData';
import postApiData from '../../api/postApiData';
import deleteApiData from '../../api/deleteApiData';

const Form = (props) => {
  /**
   * Destruct props
   */
  const {
    children,
    closeForm,
    updateContent,
    body,
    endpoint,
    method,
  } = props;

  const submit = (event) => {
    event.preventDefault();
    if (method === "POST") {
      postApiData(endpoint, body)
        .then(data => {
          updateContent(data);
          closeForm();
        });
    } else {
      putApiData(endpoint, body)
        .then(data => {
          updateContent(data);
          closeForm();
        });
    }

  }

  const remove = () => {
    deleteApiData(endpoint, body)
      .then(data => {
        updateContent(data);
        closeForm();
      });
  };

  return (
    <form
      onSubmit={submit}
      style={styles.form}
    >
      {children}
      <div style={styles.buttonWrapper}>
        <Button
          title="Avbryt"
          onClick={closeForm}
          style={{ backgroundColor: 'var(--pink)' }}
        />
        <Button
          title="Spara"
          onClick={submit}
          style={{ backgroundColor: 'var(--green)', marginLeft: '1rem' }}
        />
      </div>
      {method === 'PUT'
        ? (
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <button
              onClick={remove}
              style={styles.deleteButton}
            >
              Radera inlägg
            </button>
          </div>
        )
        : null
      }
    </form>
  )
};

export default Form;

const styles = {
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  buttonWrapper: {
    marginTop: '1rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  deleteButton: {
    color: 'var(--purple)',
    backgroundColor: 'transparent',
    borderBottom: 'none',
    fontWeight: 400,
    border: 'none',
    fontSize: '1rem',
    marginTop: '40px'
  },
};
