const removeFile = (callback, file) => {
  fetch(`/api/file/${file._id}`, {
    method: "DELETE",
    mode: "cors",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then(({ data }) => {
      callback(data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export default removeFile;
