/**
 * Input field
 */

import React from "react";

const TextArea = (props) => {
  const { onChange, value } = props;

  const update = ({ target }) => {
    onChange(target.value);
  }

  return (
    <textarea
      style={styles}
      value={value}
      onChange={update}
    />
  )
};

export default TextArea;

const styles = {
  border: '1px solid rgba(0,0,0,0.1)',
  fontWeight: 400,
  fontFamily: 'var(--text-font)',
  padding: '10px',
  borderRadius: 'var(--border-radius)',
  fontSize: '1rem',
  minHeight: '400px',
};
