import React, { useContext } from "react";
import ComponentMap from "../../components/ComponentMap";
import View from "../../components/View";
import Activity from "./Activity";
import { SET_ACTIVITIES } from "../../store/actionsTypes";
import AppState from "../../store/AppState";
import { ACTIVITIES_URL } from "../../api/urls";
import useApiData from "../../store/hooks/useApiData";
import sortActivities from "./utils/sortActivities";

const ActivitiesView = () => {
  const loading = useApiData(ACTIVITIES_URL, SET_ACTIVITIES);
  const {
    state: { activities },
  } = useContext(AppState);

  return (
    <View loading={loading}>
      <div style={styles.wrapper}>
        <ComponentMap
          Component={Activity}
          content={sortActivities(activities)}
        />
      </div>
    </View>
  );
};

export default ActivitiesView;

const styles = {
  wrapper: {
    backgroundColor: "rgb(250, 250, 252)",
    padding: "var(--content-padding)",
  },
};
