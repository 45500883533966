import React from "react";
import { englishMonths, swedishMonths } from "../../utils/DateUtils";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  wrapper: {
    fontFamily: "var(--theme-font)",
    padding: "20px",
    width: "20%",
    // textAlign: 'center',
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      flexDirection: "row",
    },
  },
  date: {
    fontWeight: 200,
    fontSize: "3rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
  month: {
    textTransform: "uppercase",
    fontWeight: 700,
    fontSize: "1rem",
    color: "var(--purple)",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
      margin: "0 10px",
    },
  },
  year: {
    fontWeight: 500,
    fontSize: "1.2rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1rem",
    },
  },
}));

const ActivityDate = ({ date, language }) => {
  const classes = useStyles();

  const getMonth = () => {
    const d = new Date(date);
    const month = d.getMonth();
    if (language === "english") {
      return englishMonths[month].long;
    }
    return swedishMonths[month].long;
  };

  return (
    <div className={classes.wrapper}>
      <span className={classes.date}>{new Date(date).getDate()}</span>
      <span className={classes.month}>{getMonth()}</span>
      <span className={classes.year}>{new Date(date).getFullYear()}</span>
    </div>
  );
};

export default ActivityDate;
