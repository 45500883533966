import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: '38%',
    fontSize: '2.4rem',
    fontFamily: '"Times", "Times New Roman", serif',
    fontWeight: 900,
    padding: '1.2rem 0',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      fontSize: '1.8rem',
      textAlign: 'center',
    },
  }
}));

const HeaderTitle = (props) => {
  const classes = useStyles();

  return (
    <Typography classes={classes}>
      Pehr Wissén AB
    </Typography>
  )
};

export default HeaderTitle;
