import React from 'react';

const Label = (props) => {
  const {
    title,
    color,
  } = props;

  return (
    <label style={{ ...styles.label, color: color || 'inherit' }}>
      {title}
    </label>
  )
}

export default Label;

const styles = {
  label: {
    fontFamily: 'var(--theme-font)',
    fontWeight: 600,
    margin: '1rem 0 0.4rem 0',
    fontSize: '1rem',
  }
}
