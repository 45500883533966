import React from 'react';
import FormattedText from '../../components/FormattedText';
import { getEmbeddableLink } from '@fippli/youtube-url-parser';
import TitleLanguageSwitch from '../../components/FormattedText/TitleLanguageSwitch';

const Video = ({ content }) => (
  <div style={styles.wrapper}>
    <div style={styles.videoWrapper}>
      <iframe
        title={content.title.data}
        src={getEmbeddableLink(content.videourl.data)}
        frameBorder="0"
        allowFullScreen
        style={styles.video}
      />
    </div>
    <div style={styles.text}>
      <TitleLanguageSwitch content={content} color="#ffffff" />
      <FormattedText
        content={content}
        color="#ffffff"
      />
    </div>
  </div>
);

export default Video;

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    margin: '5% 0',
    width: '100%',
  },
  videoWrapper: {
    width: '30%',
  },
  image: {
    width: '100%',
  },
  text: {
    width: '70%',
    padding: '0 5rem'
  },
  video: {
    height: '40v',
  },
};
