import React, { useContext } from 'react';
import FormattedText from '../../components/FormattedText';
import View from '../../components/View';
import TitleLanguageSwitch from '../../components/FormattedText/TitleLanguageSwitch';
import PhotoBanner from './PhotoBanner';
import AppState from '../../store/AppState';
import useApiData from '../../store/hooks/useApiData';
import { SET_PHOTOS_VIEW } from '../../store/actionsTypes';
import { PHOTOS_VIEW_URL } from '../../api/urls';

const PhotosView = () => {
  const { state: { photosView } } = useContext(AppState);

  const loading = useApiData(PHOTOS_VIEW_URL, SET_PHOTOS_VIEW);

  return (
    <View loading={loading}>
      <div style={styles.wrapper}>
        <div style={styles.text}>
          <TitleLanguageSwitch content={photosView} />
          <FormattedText content={photosView} />
        </div>
        <PhotoBanner />
      </div>
    </View>
  )
};

export default PhotosView;

const styles = {
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    padding: 'var(--content-padding)',
  },
  imageWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '40px 0',
  },
  image: {
    width: '50vw',
  },
  arrow: {
    padding: '0 40px',
    boxSizing: 'border-box',
    cusor: 'pointer',
  },
  text: {
    width: '50vw',
  },
  circle: {
    color: 'var(--green)',
    padding: '0 5px'
  }
};
