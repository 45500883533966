import React from "react";
import HeaderLink from "./HeaderLink";
import { makeStyles } from "@material-ui/core";
import { useContext } from "react";
import AppState from "../../../store/AppState";
import { ENGLISH } from "../../../store/initialState";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: "62%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      width: "100%",
      overflowX: "auto",
    },
  },
}));

const links = [
  {
    name: "Hem",
    englishName: "Home",
    path: "/",
  },
  {
    name: "Aktiviteter",
    englishName: "Activities",
    path: "/activities",
  },
  {
    name: "Publikationer och media",
    englishName: "Publications and media",
    path: "/publications",
  },
  {
    name: "Bilder",
    englishName: "Photos",
    path: "/photos",
  },
  {
    name: "CV",
    englishName: "CV",
    path: "/cv",
  },
];

const HeaderLinks = ({ admin }) => {
  const classes = useStyles();
  const {
    state: { language },
  } = useContext(AppState);

  return (
    <div className={classes.wrapper}>
      {links.map((link) => (
        <HeaderLink
          key={link.path}
          path={admin ? `/admin${link.path}` : link.path}
          name={language === ENGLISH ? link.englishName : link.name}
        />
      ))}
    </div>
  );
};

export default HeaderLinks;
