import React, { useContext } from 'react';
import Form from '../../components/FormElements/Form';
import Input from '../../components/FormElements/Input';
import TextArea from '../../components/FormElements/TextArea';
import Label from '../../components/FormElements/Label';
import AppState from '../../store/AppState';
import updateForm from '../../utils/updateForm';
import { BOOKS_URL } from '../../api/urls';
import ImageSelect from '../../components/ImageSelect';
import FileSelect from '../../components/FileSelect';
import { SET_PUBLICATION_FILES } from '../../store/actionsTypes';

const BookAdminForm = (props) => {
  const {
    closeForm,
    updateContent,
    method,
  } = props;

  const { state: { bookForm, publications }, dispatch } = useContext(AppState);

  return (
    <div>
      {method === 'POST'
        ? (<h2>Lägg till bok</h2>)
        : (<h2>Redigera bok</h2>)
      }
      <Form
        endpoint={BOOKS_URL}
        closeForm={closeForm}
        body={bookForm}
        updateContent={updateContent}
        method={method}
      >

        <Label title="Svensk titel" />
        <Input
          value={bookForm.title.data}
          onChange={updateForm(dispatch, 'bookForm', 'title')}
        />

        <Label title="Svensk text" />
        <TextArea
          value={bookForm.text.data}
          onChange={updateForm(dispatch, 'bookForm', 'text')}
        />

        <Label title="Engelsk titel" />
        <Input
          value={bookForm.englishtitle.data}
          onChange={updateForm(dispatch, 'bookForm', 'englishtitle')}
        />

        <Label title="Engelsk text" />
        <TextArea
          value={bookForm.englishtext.data}
          onChange={updateForm(dispatch, 'bookForm', 'englishtext')}
        />

        <Label title="Bild" />
        <ImageSelect
          url={bookForm.imageurl.data}
          onChange={updateForm(dispatch, 'bookForm', 'imageurl')}
        />

        <Label title="Väl fil" />
        <div>
          <FileSelect
            title="Välj fil"
            fileType="publication"
            files={publications}
            value={bookForm.fileurl.data}
            onChange={updateForm(dispatch, 'bookForm', 'fileurl')}
            actionType={SET_PUBLICATION_FILES}
          />
        </div>
      </Form>
    </div>
  )
};

export default BookAdminForm;
