import React, { useState, useContext } from 'react';
import HomeAdminForm from './HomeAdminForm';
import Button from '../../components/Button';
import View from '../../components/View';
import BasicContent from '../../components/BasicContent';
import { SET_HOME_VIEW } from '../../store/actionsTypes';
import AppState from '../../store/AppState';
import useApiData from '../../store/hooks/useApiData';
import { HOME_VIEW_URL } from '../../api/urls';
import FormDialog from '../../components/FormDialog';

const AdminHomeView = () => {
  const [edit, setEdit] = useState(false);
  const { state: { homeView }, dispatch } = useContext(AppState);
  const loading = useApiData(HOME_VIEW_URL, SET_HOME_VIEW);

  const updateHomeView = data => {
    dispatch({
      type: SET_HOME_VIEW,
      payload: data,
    })
  };

  return (
    <View loading={loading} admin>
      <div style={styles.wrapper}>
        <BasicContent content={homeView} />
        <div style={styles.buttonWrapper}>
          <Button
            onClick={() => { setEdit(true); }}
            title="Redigera"
            language=""
            style={styles.button}
          />
        </div>
      </div>
      <FormDialog open={edit}>
        <HomeAdminForm
          contentUrl={HOME_VIEW_URL}
          closeForm={() => { setEdit(false); }}
          updateContent={updateHomeView}
        />
      </FormDialog>
    </View>
  );
};

export default AdminHomeView;

const styles = {
  wrapper: {
    padding: 'var(--content-padding)',
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  button: {
    marginLeft: 'auto',
  },
};