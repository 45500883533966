import React, { useContext } from 'react';
import { replaceLinebreak, replaceTabs } from '../../utils/TextUtils';
import AppState from '../../store/AppState';
import { ENGLISH } from '../../store/initialState';

const fixText = (text) => replaceLinebreak(replaceTabs(text));

const FormattedText = ({ content, color }) => {
  const { state } = useContext(AppState);

  return (
    <span style={{ color }}>
      <span
        dangerouslySetInnerHTML={{
          __html: state.language === ENGLISH
            ? fixText(content.englishtext.data)
            : fixText(content.text.data)
        }}
      />
    </span>
  );
};

export default FormattedText;
