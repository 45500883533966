import React, { useContext } from 'react';
import AppState from '../../store/AppState';
import { ENGLISH } from '../../store/initialState';

const FileButton = ({ url }) => {
  const { state } = useContext(AppState);

  if ([undefined, null, ""].includes(url)) {
    return null;
  }

  return (
    <span>
      <a
        style={styles.button}
        className="purple-hover"
        href={url}
        target="_blank"
        rel="noreferrer noopener"
      >
        <i className="fas fa-file-pdf" /> {state.language === ENGLISH ? 'View PDF' : 'Visa PDF'}
      </a>
    </span>
  );
};

export default FileButton;

const styles = {
  button: {
    backgroundColor: 'var(--purple)',
    color: '#ffffff',
    fontFamily: 'var(--theme-font)',
    fontWeight: 500,
    fontSize: '13pt',
    padding: '.5rem 1rem',
    borderRadius: 'var(--border-radius)',
    borderBottom: '3px solid rgba(0,0,0,0.1)',
  },
};
